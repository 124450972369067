import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { Typography } from '@mui/material';
import SimpleListMenu from './SelectMenu.js';
import Alert from '@mui/material/Alert';

export default function BasicDatePicker({ sendStart, sendEnd, setSearchClick, searchDisable, makeFetchRequest }) {
  const [validRange, setValidRange] = useState(1)
  const [predefStart, setPredefStart] = useState(0);

  const [rangeStart, setRangeStart] = useState(dayjs().subtract(1,'week'));
  const [rangeEnd, setRangeEnd] = useState(dayjs())

  const [rangeChosen, setRangeChosen] = useState(1);


  function checkDateRangeValid(inputStartDate, inputEndDate) {
    if (dayjs(inputEndDate).isBefore(dayjs(inputStartDate))) {
      setValidRange(0)
    }
    else { // valid case
      sendStart(inputStartDate)
      sendEnd(inputEndDate)
      setValidRange(1)
      setSearchClick(true)

    }
  }

  function choosePredefined(inputPredefStart) {
    setRangeChosen(0);
    setPredefStart(inputPredefStart)
  }

  function chooseRangeStart(inputRangeStart) {
    setRangeChosen(1)
    setRangeStart(inputRangeStart)
  }

  function chooseRangeEnd(inputRangeEnd) {
    setRangeChosen(1)
    setRangeEnd(inputRangeEnd)
  }

  function chooseInput() {
    if (rangeChosen) {
      checkDateRangeValid(rangeStart, rangeEnd)
    }
    else {
      checkDateRangeValid(predefStart, dayjs())
    }
  }

  var predefPicker = (
    <Box sx={{width:'40%'}}>
      <SimpleListMenu choosePredefined={choosePredefined} />
    </Box>
  )
  var rangePicker = (
    <Box sx={{width:'40%' }} padding={2}>
      <Stack direction='row' spacing={2} sx={{width: '100'}}>
      <DatePicker sx={{margin:1, width:'50%'}} label="Start date" value={rangeStart} onChange={(newDate) => (chooseRangeStart(newDate))} disableFuture/> 
      <DatePicker sx={{margin:1, width:'50%'}} label="End date" value={rangeEnd} onChange={(newDate) => chooseRangeEnd(newDate)} disableFuture/>
      </Stack>
    </Box>
  )

  if (!rangeChosen) {
    predefPicker = (
      <Box sx={{width:'40%', borderWidth:'3px', borderRadius:'5px', borderColor:'#00274C'}} border={1}>
      <SimpleListMenu choosePredefined={choosePredefined} />
      </Box>
    )
  }
  else {
    rangePicker = (
      <Box sx={{width:'40%', borderWidth:'3px', borderRadius:'5px', borderColor:'#00274C'}} padding={2} border={1}>
        <Stack direction='row' spacing={2} sx={{width: '100'}}>
        <DatePicker sx={{margin:1, width:'50%'}} label="Start date" value={rangeStart} onChange={(newDate) => (chooseRangeStart(newDate))} disableFuture/> 
        <DatePicker sx={{margin:1, width:'50%'}} label="End date" value={rangeEnd} onChange={(newDate) => chooseRangeEnd(newDate)} disableFuture/>
        </Stack>
      </Box>
    )
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
     <Grid padding={1} margin='auto' sx={{width:'100%'}}> 
      <Stack direction='column' sx={{width: '100%'}} spacing={2}>
      <Stack direction='row' spacing={2} sx={{width: '100'}} padding={1}>
          {rangePicker}
          <Box sx={{width:'20%'}}>
            <Typography sx={{margin:1, paddingTop:'17px'}} variant="h5">
                OR
            </Typography>
          </Box>
          {predefPicker}
          </Stack>
        </Stack>
        <Button sx={{ width: '100%', height: "50px" }} onClick={() => chooseInput()} variant="outlined" disabled={searchDisable}>
            Search
        </Button>

          {validRange ? ('') : (<Box mt={1}>
          
            <Alert severity="error"> Start date must be before end date.</Alert>
             
  
            
            </Box>)}

      </Grid>
    </LocalizationProvider>

  );
}
