import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Typography } from '@mui/material';

import * as dayjs from 'dayjs'

const optionsToDate = [
    ['Last 12 hours', dayjs().subtract(12, 'hour')],
    ['Last 24 hours', dayjs().subtract(24, 'hour')],
    ['Last week', dayjs().subtract(1, 'week')],
    ['Last 2 weeks', dayjs().subtract(2, 'week')],
    ['Last month', dayjs().subtract(1, 'month')],
  ];

export default function SimpleListMenu({choosePredefined}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    choosePredefined(optionsToDate[index][1])
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
        sx={{ bgcolor: 'background.paper', width: '100%'}}
      >
        <ListItemButton
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Select predefined range:"
            secondary={optionsToDate[selectedIndex][0]}
            sx={{textAlign:'center'}}
            
          
          />
        </ListItemButton>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
   
    
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {optionsToDate.map((option, index) => (
          <MenuItem
            key={option[0]}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
            
          >
           
                {option[0]}
          
            
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
